import {signal} from '@preact/signals-react';

import {AnonymousPriceVisibility} from './ga-legacy.functions';
import {LocalStorageService} from '../local-storage/local-storage.service';
import {RecoSchemeName} from '../../shared/reco/reco.class';

type GaEcommerceEvent =
    | `add_to_cart`
    | `begin_checkout`
    | `purchaseGA4`
    | `remove_from_cart`
    | `select_item`
    | `select_promotion`
    | `view_cart`
    | `view_item_list`
    | `view_item`
    | `view_promotion`;
export type GaItemListName =
    | ``
    | `AddToOrderModal`
    | `alternate_products`
    | `AlternateProductsModal`
    | `barcode_scanner`
    | `Browse`
    | `CompareCard`
    | `ComparePage`
    | `EnrollAutoReorderModal`
    | `FrequentlyBoughtTogether`
    | `Google Shopping Landing Page`
    | `IDP`
    | `Invoice`
    | `item-detail-assortments-component`
    | `ListDetailItemList`
    | `ManageAutoReordersItemsTab`
    | `ManageAutoReordersShipmentsTab`
    | `my_shop_detail`
    | `MySDSItemCard`
    | `MyShopInventoryPage`
    | `MyShopLocationPage`
    | `Order History Items`
    | `order-detail-component`
    | `order-history-items-component`
    | `order-history-return-detail-component`
    | `order-history-view-component`
    | `Previously Purchased Slider(home)`
    | `product-list-component`
    | `ProductFamilySelect`
    | `PromoCard`
    | `QuickAdd - Header`
    | `QuickAdd - Order Detail Page`
    | `QuickAdd - Quick Add Page`
    | `QuickAdd - Quick Add Paste Page`
    | `Recently Viewed Items`
    | `Recently Viewed`
    | `Required Items`
    | `required-items-component`
    | `save for later`
    | `Saved for Later`
    | `scanner-logic`
    | `scheme_${RecoSchemeName}`
    | `scheme_available_assortments`
    | `Search Redirect`
    | `search_overlay_exact_match`
    | `search_overlay_products`
    | `Search`
    | `shared-items-component`
    | `ShippingSaverAd`
    | `ShippingSaverWidget`
    | `Special`
    | `SwitchAndSave`
    | `Top Products List`
    | `TopProductsSection-col1`
    | `TopProductsSection-col2`
    | `UpdateAutoRoShipmentModal`
    | `Vehicle Selector: Wiper Blades`;
// Also `My Shop: ${binId}`, `${gaEventLabel}`, `Lists: ${getListRes.title}`, `Lists: ${listState.title}`, `Quick View - ${ImpUrl.getUrlSegment(2)}`
// Also `itemResult-${item.id}`

interface GaAddToCartItemListName {
    orderNumber: string;
    orderLines: {item_list_name: GaItemListName; itemNum: string}[];
}

export interface GaCartItem {
    dimension16: string;
    item_id: string;
    item_list_name: GaItemListName;
    price: number;
    quantity: number;
}

interface GaEcommerceOptions {
    attributes?: {
        searchTerm: string;
    };
    ecommerceGA4: {
        affiliation?: string;
        coupon?: string;
        currency?: `USD`;
        items: GaCartItem[] | GaImpressionItem[] | GaProductDetail[] | GaPromotion[];
        shipping?: number;
        tax?: number;
        transaction_id?: string;
        value?: number;
    };
}

export interface GaImpressionItem {
    dimension16: string;
    index: number;
    item_id: string;
    item_list_name: GaItemListName;
}

export interface GaProductDetail {
    dimension16: string;
    dimension29: AnonymousPriceVisibility;
    dimension40: string;
    item_id: string;
}

export interface GaPromotion {
    creative_name: string;
    creative_slot: number;
    promotion_id: string;
    promotion_name: string;
}

export interface GaPurchaseAction {
    affiliation: string;
    coupon: string;
    shipping: number;
    tax: number;
    transaction_id: string;
    value: number;
}

// Instantiate gaAddToCartItemListNames from local storage
const localStorageService = new LocalStorageService();
const gaAddToCartItemListNamesString = localStorageService.getItem(`gaAddToCartItemListNames`);
const gaAddToCartItemListNames = signal<GaAddToCartItemListName[]>(
    gaAddToCartItemListNamesString ? JSON.parse(gaAddToCartItemListNamesString) : [],
);

/**
 * Records customer adding an item to their order
 * @param gaCartItems
 * @param searchTerm
 */
export const gaAddToCart = (gaCartItems: GaCartItem[], searchTerm: string) => {
    let totalValue = 0;
    for (const gaCartItem of gaCartItems) {
        // Record gaAddToCartItemListNames
        _recordGaAddToCartItemListNames(gaCartItem);

        // Calculate event value
        totalValue = totalValue + gaCartItem.price * gaCartItem.quantity;
    }

    // Send add_to_cart event
    gaEcommerceEvent(`add_to_cart`, {
        attributes: {searchTerm},
        ecommerceGA4: {
            currency: `USD`,
            items: gaCartItems,
            value: Math.round(totalValue * 100) / 100,
        },
    });
};

/**
 * Records customer starting the checkout process
 * @param gaCartItems
 */
export const gaBeginCheckout = (gaCartItems: GaCartItem[]) => {
    gaEcommerceEvent(`begin_checkout`, {
        ecommerceGA4: {
            items: _addItemListNameToAddToCartItems(gaCartItems),
        },
    });
};

/**
 * Push GA ecommerce event to the dataLayer
 * @param ecommerceEvent - Event name to push
 * @param ecommerceOptions - Ecommerce options to include
 */
const gaEcommerceEvent = (ecommerceEvent: GaEcommerceEvent, ecommerceOptions: GaEcommerceOptions) => {
    dataLayer.push({ecommerceGA4: null}); // Clear the previous ecommerce object
    dataLayer.push({event: ecommerceEvent, ...ecommerceOptions});
};

/**
 * Records completion of checkout
 * @param gaPurchaseAction
 * @param gaCartItems
 */
export const gaPurchase = (gaPurchaseAction: GaPurchaseAction, gaCartItems: GaCartItem[]) => {
    // Send purchaseGA4 event
    gaEcommerceEvent(`purchaseGA4`, {
        ecommerceGA4: {
            affiliation: gaPurchaseAction.affiliation,
            coupon: gaPurchaseAction.coupon,
            currency: `USD`,
            items: _addItemListNameToAddToCartItems(gaCartItems),
            shipping: gaPurchaseAction.shipping,
            tax: gaPurchaseAction.tax,
            transaction_id: gaPurchaseAction.transaction_id,
            value: gaPurchaseAction.value,
        },
    });

    // Clear recorded item list names for this order
    removeOrderFromGaAddToCartItemListNames(gaPurchaseAction.transaction_id);
};

/**
 * Records customer removing an item from their order
 * @param gaCartItems
 */
export const gaRemoveFromCart = (gaCartItems: GaCartItem[]) => {
    gaEcommerceEvent(`remove_from_cart`, {
        ecommerceGA4: {
            items: _addItemListNameToAddToCartItems(gaCartItems),
        },
    });
};

/**
 * Records customer choosing a purchasable item from a list
 * @param gaImpressionItems
 */
export const gaSelectItem = (gaImpressionItems: GaImpressionItem[]) => {
    gaEcommerceEvent(`select_item`, {
        ecommerceGA4: {
            items: gaImpressionItems,
        },
    });
};

/**
 * Records customer clicking on a promotion
 * @param gaPromotions
 */
export const gaSelectPromotion = (gaPromotions: GaPromotion[]) => {
    gaEcommerceEvent(`select_promotion`, {
        ecommerceGA4: {
            items: gaPromotions,
        },
    });
};

/**
 * Records customer viewing their "cart"
 * @param gaCartItems
 */
export const gaViewCart = (gaCartItems: GaCartItem[]) => {
    gaEcommerceEvent(`view_cart`, {
        ecommerceGA4: {
            items: _addItemListNameToAddToCartItems(gaCartItems),
        },
    });
};

/**
 * Records customer viewing the detail for an item
 * @param gaProductDetail
 */
export const gaViewItem = (gaProductDetail: GaProductDetail) => {
    gaEcommerceEvent(`view_item`, {
        ecommerceGA4: {
            items: [gaProductDetail],
        },
    });
};

/**
 * Records customer viewing a list of purchasable items
 * @param gaImpressionItems
 */
export const gaViewItemList = (gaImpressionItems: GaImpressionItem[]) => {
    gaEcommerceEvent(`view_item_list`, {
        ecommerceGA4: {
            items: gaImpressionItems,
        },
    });
};

/**
 * Records customer viewing promotions
 * @param gaPromotions
 */
export const gaViewPromotion = (gaPromotions: GaPromotion[]) => {
    gaEcommerceEvent(`view_promotion`, {
        ecommerceGA4: {
            items: gaPromotions,
        },
    });
};

/**
 * Adds item_list_name from local storage to provided gaCartItems
 * @param gaCartItems
 */
const _addItemListNameToAddToCartItems = (gaCartItems: GaCartItem[]): GaCartItem[] => {
    try {
        for (const gaCartItem of gaCartItems) {
            gaAddToCartItemListNames.value.forEach((gaAddToCartItemListName) => {
                if (gaAddToCartItemListName.orderNumber === gaCartItem.dimension16) {
                    for (const orderLine of gaAddToCartItemListName.orderLines) {
                        if (orderLine.itemNum === gaCartItem.item_id) {
                            gaCartItem.item_list_name = orderLine.item_list_name;
                        }
                    }
                }
            });
        }
    } catch (e) {
        // Catch the error to avoid affecting checkout
        // eslint-disable-next-line no-console
        console.error(e);
    }

    // Return modified gaCartItems
    return gaCartItems;
};

/**
 * Records item_list_name associated with item add to order for future reference
 * @param gaCartItem
 */
const _recordGaAddToCartItemListNames = (gaCartItem: GaCartItem) => {
    try {
        let orderExists = false;
        let itemExists = false;
        gaAddToCartItemListNames.value.forEach((gaAddToCartItemListName) => {
            if (gaAddToCartItemListName.orderNumber === gaCartItem.dimension16) {
                orderExists = true;
                for (const orderLine of gaAddToCartItemListName.orderLines) {
                    if (orderLine.itemNum === gaCartItem.item_id) {
                        itemExists = true;

                        // Update item with latest list name
                        orderLine.item_list_name = gaCartItem.item_list_name;
                    }
                }

                // Add item if it does not already exist
                if (!itemExists) {
                    gaAddToCartItemListName.orderLines.push({itemNum: gaCartItem.item_id, item_list_name: gaCartItem.item_list_name});
                }
            }
        });

        // Else add the new order and item
        if (!orderExists && !itemExists) {
            gaAddToCartItemListNames.value = [
                ...gaAddToCartItemListNames.value,
                {
                    orderNumber: gaCartItem.dimension16,
                    orderLines: [{itemNum: gaCartItem.item_id, item_list_name: gaCartItem.item_list_name}],
                },
            ];
        }

        // Save to local storage for future reference after page load
        localStorageService.setItem(`gaAddToCartItemListNames`, JSON.stringify(gaAddToCartItemListNames.value));
    } catch (e) {
        // Catch the error to avoid triggering an error in the add to order workflow
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

/**
 * Removes the provided orderNumber from the recorded gaAddToCartItemListNames
 * @param orderNumber
 */
export const removeOrderFromGaAddToCartItemListNames = (orderNumber: string) => {
    try {
        gaAddToCartItemListNames.value = gaAddToCartItemListNames.value.filter(
            (gaAddToCartItemListName) => gaAddToCartItemListName.orderNumber !== orderNumber,
        );
        localStorageService.setItem(`gaAddToCartItemListNames`, JSON.stringify(gaAddToCartItemListNames.value));
    } catch (e) {
        // Catch the error to avoid affecting checkout
        // eslint-disable-next-line no-console
        console.error(e);
    }
};
